<template>
    <div>
        <h1>you have been successfully logged out</h1>
    </div>
</template>

<script>
  // @ is an alias to /src

  export default {
    name: 'home',
    components: { }
  }
</script>
